var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "detail" } },
    [
      _vm.isLoading ? _c("loader") : _vm._e(),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.loaded
          ? _c(
              "div",
              [
                _c("div", { staticClass: "row mb-5" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 col-sm-6 mb-3 mb-sm-0" },
                    [
                      _c("router-link", { attrs: { to: { name: "room" } } }, [
                        _vm._v("« Terug naar ontwerpen")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "col-12 col-sm-6 text-center text-sm-right"
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn-primary contact",
                          attrs: {
                            href:
                              "mailto:info@igmprojecttool.nl?subject=Project: " +
                              _vm.projectName +
                              " / " +
                              _vm.design.name,
                            type: "button"
                          }
                        },
                        [_vm._v("Op zoek naar iets anders? Neem contact op")]
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12 col-lg-7" }, [
                    _c("div", { staticClass: "card" }, [
                      _c("div", { staticClass: "card-body" }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col" },
                            [
                              _c("h1", { staticClass: "mb-5" }, [
                                _vm._v(_vm._s(_vm.design.name))
                              ]),
                              _c("design-carousel", {
                                attrs: { designMedia: _vm.design.images }
                              }),
                              _c("design-included", {
                                staticClass: "mt-2",
                                attrs: {
                                  designIncluded:
                                    _vm.design.products.included_groups
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "col-12 col-lg-5 mt-3 mt-lg-0" }, [
                    _c("div", { staticClass: "card options-extensions" }, [
                      _c(
                        "div",
                        { staticClass: "card-body" },
                        [
                          _c("h2", [_vm._v("Opties en uitbreidingen")]),
                          _c("design-optional", {
                            attrs: { designOptional: _vm.optionalGroups }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "card options-extensions mt-3" }, [
                      _c(
                        "div",
                        { staticClass: "card-body" },
                        [
                          _c("h2", [_vm._v("Keuzes")]),
                          _vm._l(_vm.configurableProperties, function(
                            properties,
                            index
                          ) {
                            return _c("design-properties", {
                              key: index,
                              attrs: { designProperties: properties }
                            })
                          })
                        ],
                        2
                      )
                    ])
                  ])
                ]),
                _c("price-footer"),
                _c("modal-image", { attrs: { media: _vm.media } })
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }