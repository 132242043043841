var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.project.uuid
    ? _c(
        "div",
        {
          staticClass: "modal fade",
          attrs: {
            id: "confirmation",
            "data-backdrop": "static",
            tabindex: "-1",
            role: "dialog",
            "aria-labelledby": "confirmationLabel",
            "aria-hidden": "true"
          }
        },
        [
          _c(
            "div",
            { staticClass: "modal-dialog", attrs: { role: "document" } },
            [
              _c("div", { staticClass: "modal-content" }, [
                _vm._m(0),
                _c("div", { staticClass: "modal-body" }, [
                  _vm.disableConfirmOrder
                    ? _c(
                        "div",
                        {
                          staticClass: "alert alert-warning",
                          attrs: { role: "alert" }
                        },
                        [_vm._v(" Nog niet alle ruimten zijn geconfigureerd! ")]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "accordion", attrs: { id: "orderTotals" } },
                    _vm._l(_vm.project.rooms, function(room) {
                      return _c(
                        "div",
                        { key: "orderTotal_" + room.uuid, staticClass: "card" },
                        [
                          room.order
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass: "card-header",
                                    attrs: {
                                      id: "orderTotalHeader_" + room.uuid
                                    }
                                  },
                                  [
                                    _c("h2", { staticClass: "mb-0" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-link collapsed",
                                          attrs: {
                                            type: "button",
                                            "data-toggle": "collapse",
                                            "data-target":
                                              "#orderTotalCollapse_" +
                                              room.uuid,
                                            "aria-expanded": "false",
                                            "aria-controls":
                                              "orderTotalCollapse_" + room.uuid
                                          }
                                        },
                                        [_vm._v(" " + _vm._s(room.name) + " ")]
                                      )
                                    ])
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "collapse",
                                    attrs: {
                                      id: "orderTotalCollapse_" + room.uuid,
                                      "aria-labelledby":
                                        "#orderTotalHeader_" + room.uuid,
                                      "data-parent": "#orderTotals"
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "card-body" }, [
                                      _c("h2", { staticClass: "my-2" }, [
                                        _vm._v(_vm._s(room.order.design_name))
                                      ]),
                                      _c(
                                        "ul",
                                        { staticClass: "list-group" },
                                        [
                                          _vm._l(
                                            room.order.products.includedGroups,
                                            function(includeCategory, index) {
                                              return _c(
                                                "div",
                                                { key: index },
                                                _vm._l(
                                                  includeCategory.products,
                                                  function(include, indx) {
                                                    return _c(
                                                      "li",
                                                      {
                                                        key: indx,
                                                        staticClass:
                                                          "list-group-item"
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              includeCategory.name
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            }
                                          ),
                                          _c(
                                            "li",
                                            {
                                              staticClass:
                                                "list-group-item d-flex justify-content-end last"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "price d-flex align-items-center justify-content-between"
                                                },
                                                [
                                                  _c("small"),
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm._f("dutchCurrency")(
                                                          room.order.price
                                                        )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ]
                                          )
                                        ],
                                        2
                                      ),
                                      room.order.products.optionalGroups.length
                                        ? _c("div", [
                                            _c("h2", [
                                              _vm._v("Opties en uitbreidingen")
                                            ]),
                                            _c(
                                              "ul",
                                              { staticClass: "list-group" },
                                              _vm._l(
                                                room.order.products
                                                  .optionalGroups,
                                                function(
                                                  optionalCategory,
                                                  index
                                                ) {
                                                  return _c(
                                                    "div",
                                                    { key: index },
                                                    _vm._l(
                                                      optionalCategory.products,
                                                      function(product, indx) {
                                                        return _c(
                                                          "li",
                                                          {
                                                            key: indx,
                                                            staticClass:
                                                              "list-group-item d-flex justify-content-between align-items-center"
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "info"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    optionalCategory.name
                                                                  ) +
                                                                    ": " +
                                                                    _vm._s(
                                                                      product.name
                                                                    )
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "price d-flex align-items-center justify-content-between"
                                                              },
                                                              [
                                                                _c("small"),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "dutchCurrency"
                                                                      )(
                                                                        product.price
                                                                      )
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                            _c(
                                              "ul",
                                              { staticClass: "list-group" },
                                              [
                                                _c(
                                                  "li",
                                                  {
                                                    staticClass:
                                                      "list-group-item d-flex justify-content-end last"
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f(
                                                            "dutchCurrency"
                                                          )(
                                                            room.order
                                                              .surchargeOptional
                                                              .price
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ]
                                            )
                                          ])
                                        : _vm._e(),
                                      room.order.configurableProperties.length
                                        ? _c("div", [
                                            _c("h2", [_vm._v("Keuzes")]),
                                            _c(
                                              "ul",
                                              { staticClass: "list-group" },
                                              [
                                                _vm._l(
                                                  room.order
                                                    .configurableProperties,
                                                  function(property, index) {
                                                    return _c(
                                                      "div",
                                                      { key: index },
                                                      [
                                                        _c(
                                                          "li",
                                                          {
                                                            staticClass:
                                                              "list-group-item d-flex align-items-center"
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "info"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    property.name
                                                                  ) +
                                                                    ": " +
                                                                    _vm._s(
                                                                      property
                                                                        .option
                                                                        .name
                                                                    )
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "price d-flex align-items-center justify-content-between"
                                                              },
                                                              [
                                                                _c("small"),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "dutchCurrency"
                                                                      )(
                                                                        property
                                                                          .option
                                                                          .price
                                                                      )
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  }
                                                ),
                                                _c(
                                                  "li",
                                                  {
                                                    staticClass:
                                                      "list-group-item d-flex justify-content-end last"
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f(
                                                            "dutchCurrency"
                                                          )(
                                                            room.order
                                                              .surchargeProperties
                                                              .price
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                )
                                              ],
                                              2
                                            )
                                          ])
                                        : _vm._e(),
                                      _c(
                                        "ul",
                                        { staticClass: "list-group mt-5" },
                                        [
                                          _c(
                                            "small",
                                            {
                                              staticClass:
                                                "list-group-item d-flex justify-content-between align-items-center"
                                            },
                                            [
                                              _vm._v(" Totaal Producten: "),
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("dutchCurrency")(
                                                      room.order
                                                        .totalProductPrice
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _c(
                                            "small",
                                            {
                                              staticClass:
                                                "list-group-item d-flex justify-content-between align-items-center"
                                            },
                                            [
                                              _vm._v(" Totaal Installatie: "),
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("dutchCurrency")(
                                                      room.order
                                                        .totalInstallationPrice
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          ),
                                          _c(
                                            "li",
                                            {
                                              staticClass:
                                                "list-group-item d-flex justify-content-between align-items-center total"
                                            },
                                            [
                                              _vm._v(
                                                " Totaal meerprijs (incl. BTW): "
                                              ),
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("dutchCurrency")(
                                                      room.order.totalPrice
                                                    )
                                                  )
                                                )
                                              ])
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "text-center" },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-link btn-sm",
                                              attrs: {
                                                "data-dismiss": "modal"
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.selectRoom(
                                                    room.uuid
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("u", [
                                                _vm._v("Configuratie wijzigen")
                                              ])
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  ]
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    }),
                    0
                  ),
                  _c("div", [
                    _c("ul", { staticClass: "list-group mt-5" }, [
                      _c(
                        "small",
                        {
                          staticClass:
                            "list-group-item d-flex justify-content-between align-items-center"
                        },
                        [
                          _vm._v(" Totaal Producten: "),
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm._f("dutchCurrency")(
                                  _vm.getOrderTotal("totalProductPrice")
                                )
                              )
                            )
                          ])
                        ]
                      ),
                      _c(
                        "small",
                        {
                          staticClass:
                            "list-group-item d-flex justify-content-between align-items-center"
                        },
                        [
                          _vm._v(" Totaal Installatie: "),
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm._f("dutchCurrency")(
                                  _vm.getOrderTotal("totalInstallationPrice")
                                )
                              )
                            )
                          ])
                        ]
                      ),
                      _c(
                        "li",
                        {
                          staticClass:
                            "list-group-item d-flex justify-content-between align-items-center total"
                        },
                        [
                          _vm._v(" Totaal meerprijs (incl. BTW): "),
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm._f("dutchCurrency")(
                                  _vm.getOrderTotal("totalPrice")
                                )
                              )
                            )
                          ])
                        ]
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button", "data-dismiss": "modal" }
                    },
                    [_vm._v(" Configuratie aanpassen ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        type: "button",
                        "data-dismiss": "modal",
                        disabled: _vm.disableConfirmOrder
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.confirmOrder()
                        }
                      }
                    },
                    [_vm._v(" Bevestig en verstuur ")]
                  )
                ])
              ])
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h1", [_vm._v("Overzicht")]),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Sluiten"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }