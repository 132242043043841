<template>
  <div id="detail">
    <loader v-if="isLoading"></loader>
    <transition name="fade">
      <div v-if="loaded">
        <div class="row mb-5">
          <div class="col-12 col-sm-6 mb-3 mb-sm-0">
            <router-link :to="{ name: 'room' }"
              >&laquo; Terug naar ontwerpen</router-link
            >
          </div>
          <div class="col-12 col-sm-6 text-center text-sm-right">
            <a
              :href="
                `mailto:info@igmprojecttool.nl?subject=Project: ${projectName} / ${design.name}`
              "
              type="button"
              class="btn btn-primary contact"
              >Op zoek naar iets anders? Neem contact op</a
            >
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-7">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col">
                    <h1 class="mb-5">{{ design.name }}</h1>
                    <design-carousel
                      :designMedia="design.images"
                    ></design-carousel>
                    <design-included
                      :designIncluded="design.products.included_groups"
                      class="mt-2"
                    ></design-included>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-5 mt-3 mt-lg-0">
            <div class="card options-extensions">
              <div class="card-body">
                <h2>Opties en uitbreidingen</h2>
                <design-optional
                  :designOptional="optionalGroups"
                ></design-optional>
              </div>
            </div>
            <div class="card options-extensions mt-3">
              <div class="card-body">
                <h2>Keuzes</h2>
                <design-properties
                  v-for="(properties, index) in configurableProperties"
                  :key="index"
                  :designProperties="properties"
                ></design-properties>
              </div>
            </div>
          </div>
        </div>
        <price-footer></price-footer>
        <modal-image :media="media"></modal-image>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import $ from 'jquery';
import loader from '@/components/loader.vue';
import designCarousel from '@/components/design-carousel.vue';
import designIncluded from '@/components/design-included.vue';
import designOptional from '@/components/design-optional.vue';
import designProperties from '@/components/design-properties.vue';
import modalImage from '@/components/modal-image.vue';
import priceFooter from '@/components/price-footer.vue';
export default {
  name: 'detail',
  data() {
    return {
      loaded: false,
      media: [],
      projectName: null,
    };
  },
  components: {
    loader,
    designCarousel,
    designIncluded,
    designOptional,
    designProperties,
    modalImage,
    priceFooter,
  },
  computed: {
    ...mapGetters({
      isLoading: 'project/isLoading',
      design: 'project/getDesign',
      project: 'project/getProject',
      order: 'order/getOrder',
    }),
    projectRoom() {
      return this.project.rooms.find(item => item?.order?.design_uuid === this.design.uuid);
    },
    optionalGroups() {
      if (this.projectRoom) {
        const configuredOptionalProducts = this.projectRoom.order.products.optionalGroups.map(group => group.products ? group.products.map(product => product.uuid) : null).flat();
        this.design.products.optional_groups.forEach(group => {
          if (!group.products) {
            return;
          }
          group.products.forEach(product => {
            product.selected = configuredOptionalProducts.includes(product.uuid);
          })
        });
      }
      return this.design.products.optional_groups;
    },
    configurableProperties() {
      if (this.projectRoom) {
        this.projectRoom.order.configurableProperties.forEach(group => {
          const designGroup = this.design.configurable_properties.find(item => item.uuid === group.uuid);
          const designGroupOption = designGroup.options.find(item => item.uuid === group.option.uuid);
          designGroupOption.selected = true;
        })
      }
      return this.design.configurable_properties;
    }
  },
  methods: {
    ...mapMutations({
      setConfiguration: 'order/setConfiguration',
    })
  },
  beforeMount() {
    this.$store.dispatch('order/resetOrder');
    this.$store
      .dispatch('project/getDesign', this.$route.params.designUUID)
      .then(() => {
        if (this.projectRoom) {
          this.setConfiguration(JSON.parse(JSON.stringify(this.projectRoom.order)));
        }
        this.loaded = true;
      })
      .catch((err) => {
        this.$router.push({
          name: 'error',
          params: { errorText: err.response.data.result },
        });
      });
  },
  mounted() {
    this.$on('showImageModal', (media) => {
      this.media = media;
      $('#modalImage').modal();
      $('#modalImage').on('hidden.bs.modal', () => {
        this.media = [];
      });
    });
    setTimeout(() => {
      this.projectName = sessionStorage.getItem('project_name');
    }, 500);
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'thanks'
      || !this.projectRoom?.order?.design_uuid
      || JSON.stringify(this.order) === JSON.stringify(this.projectRoom?.order)) {
      return next();
    }
    return this.$confirm(
        'Weet je het zeker? Je hebt je wijzigingen namelijk nog niet bevestigd door het ontwerp te kiezen.',
        '',
        '',
        {
          confirmButtonText: 'Ik weet het zeker',
          confirmButtonAriaLabel: 'Ik weet het zeker!',
          showCancelButton: true,
          cancelButtonText: 'Ga terug',
          cancelButtonAriaLabel: 'Ga terug',
        }
      )
      .then(() => {
        next();
      })
      .catch(() => next(false));
  },
};
</script>
