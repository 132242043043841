<template>
  <div class="row">
    <loader v-if="isLoading"></loader>
    <div class="col col-lg-8 offset-lg-2">
      <h1>Welkom {{customerName}}</h1>
      <p>
        Vul je wachtwoord in om in te loggen en start met het samenstellen van je ruimten.
      </p>
      <div class="card">
        <div class="card-body">
          <form>
            <div class="form-group">
              <label for="password">Wachtwoord</label>
              <div class="pw-container d-flex">
                <input :type="passwordFieldType"
                       class="form-control"
                       id="password"
                       v-model="password"
                       :class="passwordError !== '' ? 'error' : ''"
                       autocomplete="current-password"
                       @input="passwordError = ''" />
                <a @click.prevent="switchVisibility()"
                   class="pw-visibility">
                  <img v-if="passwordFieldType === 'password'"
                       src="../assets/img/eye.svg" />
                  <img v-if="passwordFieldType === 'text'"
                       src="../assets/img/eye-slash.svg" />
                </a>
              </div>
              <span class="error-text">{{ passwordError }}</span>
            </div>
            <button type="submit"
                    class="btn btn-primary"
                    @click.prevent="login()">
              Inloggen
            </button>
            <a href=""
               class="pl-sm-3 mt-3 d-inline-block"
               @click.prevent="forgotPassword()">
              Wachtwoord vergeten
            </a>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import loader from "@/components/loader.vue";
export default {
  name: "authentication",
  components: {
    loader
  },
  data() {
    return {
      password: "",
      passwordError: "",
      passwordFieldType: "password",
      customerName: "",
    };
  },
  mounted() {
    setTimeout(() => {
      this.customerName = sessionStorage.getItem("customer_name");
    }, 0);
  },
  computed: {
    ...mapGetters({
      isLoading: "customer/isLoading",
      customer: "customer/getCustomer"
    })
  },
  methods: {
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    forgotPassword() {
      this.$store
        .dispatch(
          "customer/forgotPassword",
          sessionStorage.getItem("customer_UUID")
        )
        .then(() => {
          this.$toasted.success(
            "Je hebt een e-mail ontvangen, met hierin een link om het wachtwoord te resetten."
          );
        })
        .catch(err => {
          this.$router.push({
            name: "error",
            params: { errorText: err.response.data.result }
          });
        });
    },
    login() {
      switch (true) {
        case this.password === "":
          this.passwordError = "Vul een wachtwoord in alsjeblieft";
          break;
        case this.password.length < 10:
          this.passwordError = "Het wachtwoord is te kort (minimaal 10 tekens)";
          break;
        default:
          this.$store
            .dispatch("customer/login", {
              customer_uuid: sessionStorage.getItem("customer_UUID"),
              password: this.password
            })
            .then(response => {
              if (response.data.status === "authenticated") {
                this.$store
                .dispatch('project/getProject', sessionStorage.getItem('project_UUID'))
                .then(() => {
                  this.$router.push(
                    {
                      name: "rooms",
                      params: { projectUUID: sessionStorage.getItem("project_UUID") }
                    },
                    () => { }
                  );
                });
              }
            })
            .catch(err => {
              switch (err.response.data.result) {
                case "already_logged_in":
                  this.$router.push(
                    {
                      name: "rooms",
                      params: { projectUUID: sessionStorage.getItem("project_UUID") }
                    },
                    () => { }
                  );
                  break;
                case "credentials_invalid":
                  this.$toasted.error(
                    "Er is iets fout gegaan met inloggen.<br />Gebruik je het goede wachtwoord?"
                  );
                  break;
                default:
                  this.$router.push({
                    name: "error",
                    params: { errorText: err.response.data.result }
                  });
              }
            });
      }
    }
  }
};
</script>
