<template>
  <div id="error"
       class="row">
    <div class="col col-lg-8 offset-lg-2"
         v-if="error">
      <!-- DEACTIVATED -->
      <div v-if="
          error === 'archived' ||
            error === 'project_not_active' ||
            error === 'customer_or_project_not_active' ||
            error === 'customer_not_active' ||
            error === 'project_not_found' ||
            error === 'design_not_found' ||
            error === 'customer_not_found' || 
            error === 'customer_not_invited'
        ">
        <h1>Het project/account is niet gevonden of verlopen.</h1>
        <p>
          Je kunt op dit moment geen ruimten samenstellen.<br />
          Klopt dit niet? Neem contact met ons op (<a href="mailto:info@igmprojecttool.nl">info@igmprojecttool.nl</a> of <a href="tel:0418-556666">0418-556666</a>).
        </p>
      </div>
      <!-- INIT AGAIN -->
      <div v-if="error === 'required_fields_missing'">
        <h1>Klik opnieuw op de link in de email</h1>
        <p>
          We kunnen je account niet vinden, klik op de link in de ontvangen email om in te loggen/registreren.
        </p>
      </div>
      <div v-if="error === 'invalid_reset_token'">
        <h1>Er is iets fout gegaan.</h1>
        <p>
          Deze wachtwoord reset link is al gebruikt. Is dit niet het geval neem
          dan contact met ons op (<a href="mailto:info@igmprojecttool.nl">info@igmprojecttool.nl</a> of <a href="tel:0418-556666">0418-556666</a>).
        </p>
      </div>
      <div v-if="error === 'order_already_placed'">
        <h1>Je hebt je order al bevestigd.</h1>
        <p>
          Klopt dit niet? Neem contact met ons op (<a href="mailto:info@igmprojecttool.nl">info@igmprojecttool.nl</a> of <a href="tel:0418-556666">0418-556666</a>).
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "error",
  data () {
    return {
      error: ""
    };
  },
  beforeMount () {
    this.error = this.$route.params.errorText;
    console.log(this.error)
  },
  mounted () {
    this.$store.dispatch("customer/logout");
  }
};
</script>
