var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row", attrs: { id: "thanks" } },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _c("div", { staticClass: "col col-lg-8 offset-lg-2" }, [
          _c("h1", [_vm._v("Bedankt!")]),
          _c(
            "a",
            {
              staticClass: "mb-2 d-block",
              attrs: { href: "https://youtu.be/Ir-qFUqUd_4", target: "_blank" }
            },
            [
              _c("img", {
                staticClass: "img-fluid",
                attrs: {
                  src: require("../assets/img/bedanktvideo.png"),
                  alt: "Bedanktvideo"
                }
              })
            ]
          ),
          _c("p", [
            _vm._v(
              "We hebben je bevestiging in goede orde ontvangen en gaan aan de slag om dit voor je in order te zetten."
            )
          ]),
          _c("p", [
            _vm._v(
              " Je ontvangt binnen enkele ogenblikken een e-mail met bevestiging van de gekozen items. Binnen 3 werkdagen ontvang je van ons via e-mail een uitgewerkte order met tekeningen waar we je gekozen items in hebben opgenomen. "
            )
          ]),
          _c("p", [
            _vm._v(" Heb je nog vragen, neem dan contact met ons op via:"),
            _c("br"),
            _c("a", { attrs: { href: "tel:0418-655100" } }, [
              _vm._v("0418-655100")
            ]),
            _vm._v(" of "),
            _c("a", { attrs: { href: "mailto:projects@igm.nl" } }, [
              _vm._v("projects@igm.nl")
            ])
          ]),
          _c("p", [_vm._v(" Je bent nu uitgelogd. ")])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }