<template>
  <div id="thanks" class="row">
    <transition name="fade">
      <div class="col col-lg-8 offset-lg-2">
        <h1>Bedankt!</h1>
        <a class="mb-2 d-block" href="https://youtu.be/Ir-qFUqUd_4" target="_blank">
          <img class="img-fluid" src="../assets/img/bedanktvideo.png" alt="Bedanktvideo" />
        </a>
        <p>We hebben je bevestiging in goede orde ontvangen en gaan aan de slag om dit voor je in order te zetten.</p>
        <p>
          Je ontvangt binnen enkele ogenblikken een e-mail met bevestiging van de gekozen items. Binnen 3 werkdagen ontvang je van ons via e-mail een uitgewerkte order met tekeningen waar we je gekozen items in hebben opgenomen.
        </p>
        <p>
          Heb je nog vragen, neem dan contact met ons op via:<br />
          <a href="tel:0418-655100">0418-655100</a> of
          <a href="mailto:projects@igm.nl"
            >projects@igm.nl</a
          >
        </p>
        <p>
          Je bent nu uitgelogd.
        </p>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "thanks"
};
</script>
