var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", [
    _c("div", { staticClass: "container d-flex align-items-center mb-0" }, [
      _vm._m(0),
      _c("div", [
        _c(
          "h2",
          { staticClass: "d-none d-sm-none d-lg-block text-nowrap" },
          [
            !_vm.enableConfirmationModal
              ? [_vm._v(" Stel je ruimte samen ")]
              : _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: {
                      "data-toggle": "modal",
                      "data-target": "#confirmation"
                    }
                  },
                  [
                    _vm.enableConfirmationSubmit
                      ? [_vm._v(" ✓ Geconfigureerde ruimten bevestigen ")]
                      : [_vm._v(" Geconfigureerde ruimten bekijken ")]
                  ],
                  2
                )
          ],
          2
        )
      ]),
      _c(
        "div",
        { staticClass: "flex-grow-1 ml-auto mr-0 d-flex justify-content-end" },
        [
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.projectName
              ? _c("div", { staticClass: "project-name py-2" }, [
                  _c("p", [_vm._v("Project:")]),
                  _c("h4", [_vm._v(_vm._s(_vm.projectName))])
                ])
              : _vm._e()
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "align-self-start" }, [
      _c("img", {
        staticClass: "w-100 pr-2 pr-md-3 pr-lg-0",
        staticStyle: { width: "50% !important", margin: "5%" },
        attrs: {
          alt: "IGM Project Portal",
          src: require("../assets/img/igmlogoresize.jpg")
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }