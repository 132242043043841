<template>
  <div id="room">
    <loader v-if="isLoading"></loader>
    <div class="row mb-5">
      <div class="col-12">
        <router-link :to="{ name: 'rooms' }">&laquo; Terug naar ruimten</router-link>
      </div>
    </div>
    <transition name="fade">
      <div v-if="loaded">
        <div class="row">
          <div class="col">
            <h1>Selecteer ontwerp</h1>
            <p>
              Het leuke werk kan beginnen! Onderstaand vind je de ontwerpen die voor de gekozen ruimte voor jouw woning
              zijn uitgewerkt. Na selecteren van het ontwerp, wordt getoond uit welke artikelen het ontwerp is
              opgebouwd. Per ontwerp kun je keuzes maken voor alternatieve artikelen om het totaalplaatje nog beter aan
              te laten sluiten op jouw wensen.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <select-design
              :room="room"
              :design="design"
              v-for="design in room.designs"
              :key="design.uuid"
            ></select-design>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex';
  import loader from '@/components/loader.vue';
  import selectDesign from '@/components/select-design.vue';

  export default {
    name: 'room',
    components: {
      loader,
      selectDesign
    },
    computed: {
      ...mapGetters({
        isLoading: 'project/isLoading',
        room: 'project/getRoom'
      })
    },
    data() {
      return {
        loaded: false,
        roomUUID: ""
      };
    },
    beforeMount() {
      this.roomUUID = this.$route.params.roomUUID;
    },
    mounted() {
      this.$store
        .dispatch('project/getRoom', this.roomUUID)

        .then(() => {
          this.loaded = true;
        })
        .catch(err => {
          this.$router.push({
            name: 'error',
            params: { errorText: err.response.data.result }
          });
        });
      this.$store.dispatch('order/resetOrder');
    }
  };
</script>
