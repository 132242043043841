<template>
  <div class="row">
    <loader v-if="isLoading"></loader>
    <div class="col col-lg-8 offset-lg-2">
      <h1>Registreer</h1>
      <p>
        Maak hier je registratie compleet en voer een wachtwoord in.
      </p>
      <div class="card">
        <div class="card-body">
          <form>
            <div class="form-group">
              <label for="password">Kies een wachtwoord </label>
              <div class="d-flex align-items-center pw-container">
                <input :type="passwordFieldType"
                       class="form-control password"
                       :class="passwordError !== '' ? 'error' : ''"
                       id="password"
                       v-model="password"
                       @input="(passwordError = ''), checkPassword()" />
                <a @click.prevent="switchVisibility()"
                   class="pw-visibility">
                  <img v-if="passwordFieldType === 'password'"
                       src="../assets/img/eye.svg" />
                  <img v-if="passwordFieldType === 'text'"
                       src="../assets/img/eye-slash.svg" />
                </a>
                <span class="ml-2 input-check"
                      v-if="lengthOK"></span>
              </div>
              <span class="error-text">{{ passwordError }}</span>
              <small id="passwordHelp"
                     class="form-text text-muted">Het wachtwoord moet minimaal 10 tekens bevatten</small>
            </div>
            <button type="submit"
                    class="btn btn-primary"
                    @click.prevent="register()">
              Opslaan
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import loader from "@/components/loader.vue";
export default {
  name: "registration",
  components: {
    loader
  },
  data() {
    return {
      passwordError: "",
      password: "",
      lengthOK: false,
      passwordFieldType: "password"
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "customer/isLoading",
      customer: "customer/getCustomer"
    })
  },
  methods: {
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    checkPassword() {
      this.lengthOK = this.password.length >= 10 ? true : false;
    },
    register() {
      switch (true) {
        case this.password === "":
          this.passwordError = "Vul een wachtwoord in alsjeblieft";
          break;
        case this.password.length < 10:
          this.passwordError = "Het wachtwoord is te kort (minimaal 10 tekens)";
          break;
        default:
          this.$store
            .dispatch("customer/register", {
              uuid:
                this.customer.uuid || sessionStorage.getItem("customer_UUID"),
              password: this.password
            })
            .then(() => {
              this.$toasted.success("Registratie voltooid, je kunt nu inloggen");
              this.$router.push({ name: "authentication" });
            })
            .catch(err => {
              if (
                err.response.data.result === "password_constraints_violation"
              ) {
                this.passwordError =
                  "Het wachtwoord is te kort (minimaal 10 tekens)";
              } else {
                this.$router.push({
                  name: "error",
                  params: { errorText: err.response.data.result }
                });
              }
            });
      }
    }
  }
};
</script>
